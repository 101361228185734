// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/
var imagingMessages = {
'codipacient' : "Please, add the Patient ID",
'datanaixement' : "Please, add the date of birth",
'session_image' : "Please, select a .ZIP file",
'session_scan' : "Please,  set the scan date",
'start_date' : "Please,  set the start time",
'end_date' : "Please,  set the end time",
'responsable_nom' : "Please,  set your name",
'responsable_email' : "Please,  set your email"
}

function HCTest() {
  'use strict';

  var objDiv;
  var strColor;
  var objFlag;

  // reference to img element used to check if images are disabled
  objFlag = document.getElementById('flag');
  if (objFlag === null) {
    return;
  }

  // Create a test div
  objDiv = document.createElement('div');

  // Set its color style to something unusual
  objDiv.style.color = 'rgb(31,41,59)';

  // Attach to body so we can inspect it
  document.body.appendChild(objDiv);

  // Use standard means if available, otherwise use the IE methods

  strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

  // get rid of extra spaces in result
  // strColor = strColor.replace(/ /g,"");

  // Delete the test DIV

  document.body.removeChild(objDiv);

  // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
  // Use .offsetwidth and .readyState (for IE) to check if images are enabled
  // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

  if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

    var objHead = document.getElementsByTagName('head');
    var objCSS = objHead[0].appendChild(document.createElement('link'));
    objCSS.rel = 'stylesheet';
    objCSS.href = '/media/css/alt.css';
    // objCSS.type = 'text/css';
  }
}

// Safely initiate foundation now
/*$(function() {
  $(document).foundation();
});*/

// the rest of your code
/*
$(window).load(function() {
  'use strict';

  // accessible image sprites
  HCTest();
});
*/
/*
$(function(){
  $('.houdiniForm .hasDatepicker').fdatepicker();
});
*/

/*
$(document).mouseup(function(e){

  var container = $(".btn-dropdown ul");

  // If the target of the click isn't the container
  //if(!container.is(e.target) && container.has(e.target).length === 0){
      //container.hide();
      //console.log(container);
      if(container.hasClass('open')){
        container.removeClass('open');
      }
  //}
});*/

$(document).ready(function() {


  ("use strict");

  // mobile-nav
  $('.menu-trigger').click(
    function() {
      if (!$('.main-nav').hasClass('open')) {
        $(this).addClass('is-hidden');
        $('.main-nav').addClass('open');
      } else {
        $('.menu-trigger-close').removeClass('is-hidden');
        $('.main-nav').removeClass('open');
      }
      return false;
    }
  );
  $('.menu-trigger-close').click(
    function() {
      if (!$('.main-nav').hasClass('open')) {
        $(this).addClass('is-visible');
        $('.main-nav').addClass('open');
      } else {
          $('.main-nav').removeClass('open');
          $('.menu-trigger').removeClass('is-hidden');
        }
      return false;
    }
  );



   // ------------
  // input type number
  // ------------
  // ------------


  jQuery('<div class="quantity-nav"><div class="quantity-button quantity-up"></div><div class="quantity-button quantity-down"></div></div>').insertAfter('.controls input[type="number"]');
  jQuery('.controls').each(function() {
	var spinner = jQuery(this),
	  input = spinner.find('input[type="number"]'),
	  btnUp = spinner.find('.quantity-up'),
	  btnDown = spinner.find('.quantity-down'),
	  min = input.attr('min'),
	  max = input.attr('max');
	  //input.val(0);

	btnUp.click(function() {
	  var oldValue = parseFloat(input.val());
	  if (oldValue >= max) {
		var newVal = oldValue;
	  } else {
		var newVal = oldValue + 1;
	  }
	  spinner.find("input").val(newVal);
	  spinner.find("input").trigger("change");
	});

	btnDown.click(function() {
	  var oldValue = parseFloat(input.val());
	  if (oldValue <= min) {
		var newVal = oldValue;
	  } else {
		var newVal = oldValue - 1;
	  }
	  spinner.find("input").val(newVal);
	  spinner.find("input").trigger("change");
	});

  });

  // ------------
  // file-name
  // ------------
  // ------------

  $(".input-file").change(function(e){

   /* console.log(e.target);
    console.log(e.target.files[0].name);
    console.log(e.target.labels[0].attributes[1].nodeValue);*/

    var arxiu = e.target.files[0].name;
    var etiqueta = e.target.labels[0].attributes[1].nodeValue;

    $('label').each(function(){

      if ($(this).attr('for') == etiqueta) {
        //console.log($(this).find('.text-right').html());
        $(this).find('.text-right').html(arxiu);
      }

    });


  });



   // ------------
  // btn-dropdown cog
  // ------------
  // ------------

  $("body").mouseup(function(e){

    var container = $(".btn-dropdown");
    var container2 = $(".btn-dropdown ul");

    if(!container.is(e.target) && container.has(e.target).length === 0){

        //console.log(container);
        if(container2.hasClass('open')){
          container2.removeClass('open');
        }
    }
  });
/*
  $("body").mousedown(function(e){
    $(this).after("<p style='color:purple;'>Mouse button pressed down.</p>");
  });*/


  $(".btn-dropdown button").click(function() {

	if( $(this).next('ul').hasClass('open')){
		$(this).next('ul').removeClass('open');
		$(this).removeClass('open');
	} else {
		$('.btn-dropdown').each(function(){
			$('.btn-dropdown ul').removeClass('open');
			$(this).removeClass('open');
		});

		$(this).next('ul').addClass('open');
		$(this).addClass('open');
	}

  });


   // ------------
  // alert
  // ------------
  $('.alert button').click(
    function() {
      $('.alert').fadeOut(300, function() { $(this).remove(); });
    }
  );

  setTimeout(function(){
    if ($('#alert-box').length > 0) {
       $('#alert-box').fadeOut(300, function() { $(this).remove(); });
    }
  }, 3000)

   // ------------
  // admin studies ended
  // ------------

    /*$( "#from" ).fdatepicker({
      defaultDate: "+1w",
      changeMonth: true,
      changeYear: true,
      numberOfMonths: 2,
      onSelect: function( selectedDate ) {
        $( "#to" ).fdatepicker( "option", "minDate", selectedDate );
      }
    });


    $( "#to" ).fdatepicker({
      defaultDate: "+1w",
      changeMonth: true,
      changeYear: true,
      numberOfMonths: 2,
      onSelect: function( selectedDate ) {
        $( "#from" ).fdatepicker( "option", "maxDate", selectedDate );
      }
    });*/

	$("#trimestre").change(function(){

    var fecha = new Date();
    var any = fecha.getFullYear();
		switch($(this).val()) {
			case '0':
			$( "#from" ).val('');
			$( "#to" ).val('');
			break;
			case '1':
				$( "#from" ).val(any + '-01-01');
				$( "#to" ).val(any + '-03-31');
				break;
			case '2':
				$( "#from" ).val(any + '-04-01');
				$( "#to" ).val(any + '-06-30');
				break;
			case '3':
				$( "#from" ).val(any + '-07-01');
				$( "#to" ).val(any + '-09-30');
				break;
			case '4':
				$( "#from" ).val(any + '-10-01');
				$( "#to" ).val(any + '-12-31');

				break;

		}
	});

   // ------------
  // zoom admin patient view
  // ------------

  $(".zoom").elevateZoom( {
		zoomType : "inner",
			//zoomType : "lens",
			//lensShape : "square",
			//lensSize    : 200
		}
	);

  	// ------------
  	// clients study add
  	// ------------
  	if($("body#centre_study_add").length ) {

//	  var show_bar = 0;
//	  $('input[type="file"]').click(function(){
//	    show_bar = 1;
//	  });
//
//	  $("#newform").submit(function(){
//
//	    if (show_bar === 1) {
//	      $('#upload_frame').show();
//	      function set () {
//	        $('#upload_frame').attr('src', appUrl + '/upload_frame.php?up_id=<?php echo $up_id; ?>');
//	      }
//	      setTimeout(set);
//	    }
//	  });
//



	  var catcher = function() {
		  var changed = false;
		  $('form').each(function() {
		    if ($(this).data('initialForm') != $(this).serialize()) {
		      changed = true;
		      $(this).addClass('changed');
		    } else {
		      $(this).removeClass('changed');
		    }
		  });
		  if (changed) {
		    return 'One or more forms on this page have changed.  Are you sure you want to leave this page?';

		  }
		};

		$(function() {
			$('form').each(function() {
				$(this).data('initialForm', $(this).serialize());
			});
			$(window).bind('beforeunload', catcher);
		});

		$(function() {

			/*	$( "#birthday" ).fdatepicker({
					showOn: "both",
					buttonImage: urlBase + "/media/intranet/css/img/Calendar-Icon.png",
					buttonImageOnly: true,
					changeMonth: true,
					changeYear: true,
					yearRange: 'c-100:c',
					dateFormat: 'dd/mm/yy'
				});
				$( "#session_scan" ).fdatepicker({
					showOn: "both",
					buttonImage: urlBase + "/media/intranet/css/img/Calendar-Icon.png",
					buttonImageOnly: true,
					dateFormat: 'dd/mm/yy'
				});*/
			});


		//validar formulari

			$("#newform").validate({
				 ignore: [],
				 debug:false,
//			     errorElement:"span",
			     errorClass:"error",
			     validClass:"",
//			     errorLabelContainer: $("#newform div.error"),

			     unhighlight: function(element, errorClass, validClass) {
						if (element.type === 'radio') {
							this.findByName(element.name).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
						} else {
							$(element).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
			                $(element).next("span").addClass("okIcon");
						}
			    },


			    highlight: function(element, errorClass, validClass) {
			    		$("div#error-container").removeClass("hidden");
						if (element.type === 'radio') {
							this.findByName(element.name).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
						} else {

			                $(element).next("span").removeClass("okIcon");
							$(element).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
			                $(element).parent("div").parent("div").css("margin-left","0px")
			                                                      .css("padding-left","0px")
			                                                      .css("padding-top","0px")
			                                                      .css("padding-bottom","0px")
			                                                      .css("margin-bottom","9px");
						}
					},

				rules: {
					code: "required",
					birthday: "required",
					session_image: {
						required: true,
						extension: 'zip' 
					},
					session_scan: "required",
					responsable_nom: "required",
					responsable_email: {'required' : true , 'email' : true},

				},
				messages: {
					code: imagingMessages.codipacient,
					birthday: imagingMessages.datanaixement,
					session_image: imagingMessages.session_image,
					session_scan: imagingMessages.session_scan,
					responsable_nom: imagingMessages.responsable_nom,
					responsable_email: imagingMessages.responsable_email,
				}


			});
			$("#enviar-form").click(function() {
				window.scrollTo(0, location.hash);
				if($("#newform").valid()){
					$(window).unbind('beforeunload', catcher);//treure el avis de canvis no guardats
					$("#sending-container").show();
					$(document.body).prepend('<div id="upload_overlay"></div>');
				}
				$('#newform').submit();

			});
			$(".guardar-form").click(function() {
				window.scrollTo(0, location.hash);

				if($("#newform").valid()){
					$(window).unbind('beforeunload', catcher);//treure el avis de canvis no guardats
					$("#sending-container").show();
					$(document.body).prepend('<div id="upload_overlay"></div>');
				}
				$('#newform').submit(function() {
					  var input = $("<input>").attr("type", "hidden").attr("name", "savedata").val("1");
					  $('#newform').append($(input));

					});


			});




	}

   // ------------
  // Table
  // ------------

	/*$('td.showtooltip').each(function(){
		var value = $(this).find('p.hidden').text();
		$(this).tooltip(
				{
					'title' : value
				}
			);
	});*/

  $(document).foundation({
    tooltip: {
      selector : '.has-tip',
      additional_inheritable_classes : [],
      tooltip_class : '.tooltip',
      touch_close_text: 'tap to close',
      disable_for_touch: false,
      tip_template : function (selector, content) {
        return '<span data-selector="' + selector + '" class="'
          + Foundation.libs.tooltip.settings.tooltip_class.substring(1)
          + '">' + content + '<span class="nub"></span></span>';
      }
    }
  });

  /*$(document).foundation('tab', 'reflow');

  $('#myTabs').on('toggled', function (event, tab) {
    console.log(tab);
  });*/

  // ------------
  // Menú nav-top
  // ------------

  $(".has-dropdown").click(function() {
    // $("ul.lang abbr").click(function() {
    $(this).toggleClass("open");
  });

  // -----------------
  // Smooth scroll top
  // -----------------

  if (window.matchMedia('(min-width: 64em)').matches) {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
        $(".top").fadeIn();
      } else {
        $(".top").fadeOut();
      }
    });
  };


  // ----------
  // Datepicker
  // ----------

  //datapicker


/*
  $(function() {
    $(".date").fdatepicker({
      format: "dd-mm-yyyy",
      language: "es",
      weekStart: 1,
      leftArrow:
        '<button type="button"><img src="/media/img/bt-calendario-anterior.png" srcset="/media/img/bt-calendario-anterior@2x.png 2x" width="24" height="24" alt="Mes anterior" /></button>',
      rightArrow:
        '<button type="button"><img src="/media/img/bt-calendario-siguiente.png" srcset="/media/img/bt-calendario-siguiente@2x.png 2x" width="24" height="24" alt="Mes siguiente" /></button>'
    });
  });*/

  /*
  $(function() {
    //$('a[name]').addClass('anchor_offset');
    /*en cas de menú sticky, alçada àncores. Afegir css:
    .offset:before {
      content: " ";
      display: block;
      height: 120px; en funció l'alçada del menú sticky
      margin-top: -120px; en funció l'alçada del menú sticky
      visibility: hidden;
      }*/
   /* $('a[href*="#"]:not([href="#"],[href="#menu"])').click(function(e) {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        e.preventDefault();
        history.pushState({}, "", this.href);
        $('html, body').animate({
        scrollTop: target.offset().top
        }, 1000);
      return false;
      }
    }
    });
  });
  }*/

  //al validar formulari fer scroll tingui en compte sticky header
  /*$("input,textarea").focus(function(){
    var elementOffset = $(this).offset().top,
    scrollTop     = $(window).scrollTop(),
    distance      = (elementOffset - scrollTop);
    var label = $(this).closest('div').find('label');
    //modicar classes depent de projecte
    var addSpace = $('.main-header.sticky').height() + label.height() + 10 - distance;

    if(addSpace > 0)
    {
      var y = $(window).scrollTop();
      $(window).scrollTop(y-addSpace);
    }
  });


});*/


  /*
   * Botó descarregar els estudis pels administradors/coordinadors
   */
  $('a.btn-download-studies-action').click (function (){
		parent.window.open($(this).attr('href'));
		setTimeout(function(){parent.window.location.reload();},1000);
		return false;

  });

  /*
   * Client studi seesion add
   */
  
  
  
  if( $('#sending-container').length && $('#upload_overlay').length) {
	  var show_bar = 0;
      $('input[type="file"]').click(function(){
      	show_bar = 1;
      });

      // upload progress bar
      // show iframe on form submit
      $("#newform").submit(function(){

      	if (show_bar === 1) {

				var progress = 0,
				current_progress = 0,
			    step = 0.25; // the smaller this is the slower the progress bar
  		    interval = setInterval(function() {
  		        current_progress += step;
  		        progress = Math.round(Math.atan(current_progress) / (Math.PI / 2) * 100 * 1000) / 1000
  		        $(".meter")
  		            .css("width", progress + "%")
  		            .attr("aria-valuenow", progress);
  		        if (progress >= 100){
  		            clearInterval(interval);
  		        }else if(progress >= 70) {
  		            step = 0.1
  		        }
  		    }, 200);

      	}
      });
  }  

  if( $('body#centre_study_session_add').length ) {



	  var catcher = function() {
	    var changed = false;
	    $('form').each(function() {
	      if ($(this).data('initialForm') != $(this).serialize()) {
	        changed = true;
	        $(this).addClass('changed');
	      } else {
	        $(this).removeClass('changed');
	      }
	    });
	    if (changed) {
	      return 'One or more forms on this page have changed.  Are you sure you want to leave this page?';

	    }
	  };

	  $(function() {
	  	$('form').each(function() {
	  		$(this).data('initialForm', $(this).serialize());
	  	});
	  	$(window).bind('beforeunload', catcher);
	  });

	  $(function() {

	  	/*	$( "#birthday" ).fdatepicker({
	  			showOn: "both",
	  			buttonImage: urlBase + "/media/intranet/css/img/Calendar-Icon.png",
	  			buttonImageOnly: true,
	  			changeMonth: true,
	  			changeYear: true,
	  			yearRange: 'c-100:c',
	  			dateFormat: 'dd/mm/yy'
	  		});
	  		$( "#session_scan" ).fdatepicker({
	  			showOn: "both",
	  			buttonImage: urlBase + "/media/intranet/css/img/Calendar-Icon.png",
	  			buttonImageOnly: true,
	  			dateFormat: 'dd/mm/yy'
	  		});*/
	  	});


	  	$("#newform").validate({
	  		 debug:false,
//	  	     errorElement:"span",
	  	     errorClass:"error",
	  	     validClass:"",
//	  	     errorLabelContainer: $("#newform div.error"),

	  	     unhighlight: function(element, errorClass, validClass) {
	  				if (element.type === 'radio') {
	  					this.findByName(element.name).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
	  				} else {
	  					$(element).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
	  	                $(element).next("span").addClass("okIcon");
	  				}
	  	    },


	  	    highlight: function(element, errorClass, validClass) {
	  	    		$("div#error-container").removeClass("hidden");
	  				if (element.type === 'radio') {
	  					this.findByName(element.name).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
	  				} else {

	  	                $(element).next("span").removeClass("okIcon");
	  					$(element).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
	  	                $(element).parent("div").parent("div").css("margin-left","0px")
	  	                                                      .css("padding-left","0px")
	  	                                                      .css("padding-top","0px")
	  	                                                      .css("padding-bottom","0px")
	  	                                                      .css("margin-bottom","9px");
	  				}
	  			},

	  		rules: {
//	  			code: "required",
//	  			birthday: "required",
	  			session_image: "required",
	  			session_scan: "required",
	  			responsable_nom: "required",
	  			responsable_email: {'required' : true , 'email' : true},

	  		},
	  		messages: {
//	  			code: imagingMessages.codipacient,
//	  			birthday: imagingMessages.datanaixement,
	  			session_image: imagingMessages.session_image,
	  			session_scan: imagingMessages.session_scan,
	  			responsable_nom: imagingMessages.responsable_nom,
	  			responsable_email: imagingMessages.responsable_email,
	  		}


	  	});
	  	$("#enviar-form").click(function() {
	  		window.scrollTo(0, location.hash);
	  		if($("#newform").valid()){
	  			$(window).unbind('beforeunload', catcher);//treure el avis de canvis no guardats
	  			$("#sending-container").show();
	  			$(document.body).prepend('<div id="upload_overlay"></div>');
	  		}
	  		$('#newform').submit();

	  	});
	  	$(".guardar-form").click(function() {
	  		window.scrollTo(0, location.hash);

	  		if($("#newform").valid()){
	  			$(window).unbind('beforeunload', catcher);//treure el avis de canvis no guardats
	  			$("#sending-container").show();
	  			$(document.body).prepend('<div id="upload_overlay"></div>');
	  		}
	  		$('#newform').submit(function() {
	  			  var input = $("<input>").attr("type", "hidden").attr("name", "savedata").val("1");
	  			  $('#newform').append($(input));

	  			});


	  	});


  }
  /*
   * Formularis QC, Preprocessing, ....
   */
  if ($("body#analyze-form").length) {
	    var catcher = function() {
		  var changed = false;
		  $('form').each(function() {
		    if ($(this).data('initialForm') != $(this).serialize()) {
		      changed = true;
		      $(this).addClass('changed');
		    } else {
		      $(this).removeClass('changed');
		    }
		  });
		  if (changed) {
		    return 'One or more forms on this page have changed.  Are you sure you want to leave this page?';
		  }
		};

		$(function() {
			$('form').each(function() {
				$(this).data('initialForm', $(this).serialize());
			});
			$(window).bind('beforeunload', catcher);
		});

		//enviar formulari
		$("#enviar-form").click(function() {
			window.scrollTo(0, location.hash);
			if($("#newform").valid()){
				$(window).unbind('beforeunload', catcher);//treure el avis de canvis no guardats
				$("#sending-container").show();
				$(document.body).prepend('<div id="upload_overlay"></div>');
			}
			$('#newform').submit(function() {
				  var input = $("<input>").attr("type", "hidden").attr("name", "validatedata").val("1");
				  $('#newform').append($(input));
			});

		});
		$(".guardar-form").click(function() {
			window.scrollTo(0, location.hash);

			if($("#newform").valid()){
				$(window).unbind('beforeunload', catcher);//treure el avis de canvis no guardats
				$("#sending-container").show();
				$(document.body).prepend('<div id="upload_overlay"></div>');
				
				$('#newform').submit(function() {
					  var input = $("<input>").attr("type", "hidden").attr("name", "savedata").val("1");
					  $('#newform').append($(input));
				});
			}
			
		});


		/*
		 * Formulari QC
		 */
		if ($("body#analyze-form.qc").length) {
			$("#newform").validate({
//				 debug:true,
			     errorElement:"label",
			     errorClass:"error",
//			     validClass:"",
			     errorLabelContainer: $("#error-container"),

			     unhighlight: function(element, errorClass, validClass) {
						if (element.type === 'radio') {
							this.findByName(element.name).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
						} else {
							$(element).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
			                $(element).next("span").addClass("okIcon");
						}
			    },


			    highlight: function(element, errorClass, validClass) {
			    		$("div#error-container").removeClass("hidden");
						if (element.type === 'radio') {
							this.findByName(element.name).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
						} else {

			                $(element).next("span").removeClass("okIcon");
							$(element).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
			                $(element).parent("div").parent("div").css("margin-left","0px")
			                                                      .css("padding-left","0px")
			                                                      .css("padding-top","0px")
			                                                      .css("padding-bottom","0px")
			                                                      .css("margin-bottom","9px");
						}
					},

				rules: {
					scan_result: "required",
					incidence: {
						required: {
				         	depends: function() {
				           		return document.getElementById('comprovacio_scan').checked
							}
						}
				    }

				},
				messages: {
					scan_result: "Please, select an option: Ok, Reject or Incident",
					incidence: "Please, select an incident type"
				}


			});

			//en cas que inidencia sigui si habilitem el formulari
			$("input:radio[name=scan_result]").click(function() {
			    var value = $(this).val();

			    if (value == '3') {
			       $("select#incidence").removeClass('disabled');
			       $("select#incidence").removeAttr('disabled');
			       $("span.btn-warning").addClass('seleccionat');
			       $("span.btn-success").removeClass('seleccionat');
			       $("span.btn-danger").removeClass('seleccionat');
			    }else if(value == '2' ){
			    	$("select#incidence").addClass('disabled');
			        $("select#incidence").attr('disabled','');
			        $("span.btn-danger").addClass('seleccionat');
				    $("span.btn-success").removeClass('seleccionat');
				    $("span.btn-warning").removeClass('seleccionat');
			    }else if(value == '1' ){
			    	$("select#incidence").addClass('disabled');
			        $("select#incidence").attr('disabled','');
			        $("span.btn-success").addClass('seleccionat');
				    $("span.btn-warning").removeClass('seleccionat');
				    $("span.btn-danger").removeClass('seleccionat');
			    }
			});


			if ($("body#analyze-form.qc.preview").length) {
				var validateQC = function() {
					$("#preview-form").removeClass('guardar-form');
					$("#preview-form").attr('name', 'save');
					var inputValidate = $("<input>").attr("type", "hidden").attr("name", "validate").val("1");
					$('#newform').append($(inputValidate));
					$('#newform').attr('action', $('#newform').attr('action').replace('?preview=true',''));
				}


				if ($("#modalPreviewPDF").length) {
					$('#modalPreviewPDF').foundation('reveal', 'open');

					$('#confirm-qc').on('click', function(e){
						e.preventDefault();
						validateQC();
						$("#preview-form").trigger('click');
						return false;
					});
				}
			}
		}
		// Fi QC
		
		/*
		 * Formulari Preprocessing
		 */
		if ($("body#analyze-form.reorientacio").length) {
			$("#newform").validate({
				ignore: [],
//				 debug:true,
			     errorElement:"label",
			     errorClass:"error",
//			     validClass:"",
//			     errorLabelContainer: $("#error-container"),

			     unhighlight: function(element, errorClass, validClass) {
						if (element.type === 'radio') {
							this.findByName(element.name).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
						} else {
							$(element).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
			                $(element).next("span").addClass("okIcon");
						}
			    },


			    highlight: function(element, errorClass, validClass) {
//			    		$("div#error-container").removeClass("hidden");
						if (element.type === 'radio') {
							this.findByName(element.name).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
						} else {

			                $(element).next("span").removeClass("okIcon");
							$(element).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
			                $(element).parent("div").parent("div").css("margin-left","0px")
						}
					},

				rules: {
					image_axial: {
						extension: "jpg"
					},
					image_coronal: {
						extension: "jpg"
					},
					image_sagital: {
						extension: "jpg"
					},
					image_analyze_zip: {
						extension: "zip"
					}
				},
//				messages: {
//					scan_result: "Si us plau, marqueu una opció: Ok, Rebutja o Incidència",
//					incidence: "Si us plau, seleccioneu algun tipus d'incidència"
//				}


			});
		}
		// Fi Preprocessing		


		/*
		 * Qualitatiu: PET report
		 */
		if ($("body#analyze-form.qualitatiu.preview").length) {
			if ($("#modalPreviewPDF").length) {
				$('#modalPreviewPDF').foundation('reveal', 'open');

				$('#confirm-qc').on('click', function(e){
					$("#preview-form").removeClass('guardar-form');
	    			var inputValidate = $("<input>").attr("type", "hidden").attr("name", "validate").val("1");
	  				$('#newform').append($(inputValidate));
	  				$(".guardar-form").trigger('click');
				});
			}
		}
		// Fi Qualitatiu


		/*
		 * Quantitatiu: Quantitative report
		 */
		if ($("body#analyze-form.quantitatiu").length) {
//			$('#importer').ajaxForm({
//				url: '/media/intranet/php/quantitatiu/upload.php',
//				beforeSubmit: function() {
//					$('#results').html('<p><em>Enviant dades del fitxer...</em></p>');
//				},
//				success: function(data) {
//					if (data && data != '') {
//						// per navegadors vells
//						data = data.replace('<textarea>', '');
//						data = data.replace('<TEXTAREA>', '');
//						data = data.replace('</textarea>', '');
//						data = data.replace('</TEXTAREA>', '');
//
//						result = jQuery.parseJSON(data);
//
//						$('input#frontal_r').val(result.row0);
//						$('input#frontal_l').val(result.row1);
//						$('input#frontal_r_percent').val(result.row2);
//						$('input#frontal_l_percent').val(result.row3);
//
//						$('input#insula_r').val(result.row4);
//						$('input#insula_l').val(result.row5);
//						$('input#insula_r_percent').val(result.row6);
//						$('input#insula_l_percent').val(result.row7);
//
//						$('input#estruclimbiques_r').val(result.row8);
//						$('input#estruclimbiques_l').val(result.row9);
//						$('input#estruclimbiques_r_percent').val(result.row10);
//						$('input#estruclimbiques_l_percent').val(result.row11);
//
//						$('input#occipital_r').val(result.row12);
//						$('input#occipital_l').val(result.row13);
//						$('input#occipital_r_percent').val(result.row14);
//						$('input#occipital_l_percent').val(result.row15);
//
//						$('input#parietal_r').val(result.row16);
//						$('input#parietal_l').val(result.row17);
//						$('input#parietal_r_percent').val(result.row18);
//						$('input#parietal_l_percent').val(result.row19);
//
//						$('input#ganglis_r').val(result.row20);
//						$('input#ganglis_l').val(result.row21);
//						$('input#ganglis_r_percent').val(result.row22);
//						$('input#ganglis_l_percent').val(result.row23);
//
//						$('input#temporal_r').val(result.row24);
//						$('input#temporal_l').val(result.row25);
//						$('input#temporal_r_percent').val(result.row26);
//						$('input#temporal_l_percent').val(result.row27);
//
//						$('input#cerebel_r').val(result.row28);
//						$('input#cerebel_l').val(result.row29);
//						$('input#cerebel_r_percent').val(result.row30);
//						$('input#cerebel_l_percent').val(result.row31);
//
//
//						$('#importresults').html('<div class="alert alert-success"><button type="button" class="close" data-dismiss="alert">×</button><strong>Fitxer importat correctament.</strong></div>');
//					} else {
//						$('#importresults').html('<div class="alert alert-error"><button type="button" class="close" data-dismiss="alert">×</button><strong>Les dades del fitxer no s\'han pogut importar.</strong></div>');
//					}
//
//				}
//			});

			$("#newform").validate({
				 ignore: [],
				 debug:false,
			     errorElement:"label",
			     errorClass:"error",
			     validClass:"",
			     errorLabelContainer: $("#error-container"),

				     unhighlight: function(element, errorClass, validClass) {
//							if (element.type === 'radio') {
//								this.findByName(element.name).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
//							} else {
//								$(element).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
//				                $(element).next("span").addClass("okIcon");
//							}
				    	 $(element).removeClass('error');
				    },
				    highlight: function(element, errorClass, validClass) {
				    		$("div#error-container").removeClass("hidden");
				    		$(element).addClass('error');
//							if (element.type === 'radio') {
//								this.findByName(element.name).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
//							} else {
//
//				                $(element).next("span").removeClass("okIcon");
//								$(element).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
//				                $(element).parent("div").parent("div").css("margin-left","0px")
//							}
						},

				rules: {
					frontal_r: {
						range: [0, 100]
					},
					frontal_l: {
						range: [0, 100]
					},
					insula_r: {
						range: [0, 100]
					},
					insula_l: {
						range: [0, 100]
					},
					estruclimbiques_r: {
						range: [0, 100]
					},
					estruclimbiques_l: {
						range: [0, 100]
					},
					occipital_r: {
						range: [0, 100]
					},
					occipital_l: {
						range: [0, 100]
					},
					parietal_r: {
						range: [0, 100]
					},
					parietal_l: {
						range: [0, 100]
					},
					ganglis_r: {
						range: [0, 100]
					},
					ganglis_l: {
						range: [0, 100]
					},
					temporal_r: {
						range: [0, 100]
					},
					temporal_l: {
						range: [0, 100]
					},
					cerebel_r: {
						range: [0, 100]
					},
					cerebel_l: {
						range: [0, 100]
					},
					fileInput: {
						extension: "jpg"
					},
					frontal_r_percent: {
						range: [0, 100]
					},
					frontal_l_percent: {
						range: [0, 100]
					},
					insula_r_percent: {
						range: [0, 100]
					},
					insula_l_percent: {
						range: [0, 100]
					},
					estruclimbiques_r_percent: {
						range: [0, 100]
					},
					estruclimbiques_l_percent: {
						range: [0, 100]
					},
					occipital_r_percent: {
						range: [0, 100]
					},
					occipital_l_percent: {
						range: [0, 100]
					},
					parietal_r_percent: {
						range: [0, 100]
					},
					parietal_l_percent: {
						range: [0, 100]
					},
					ganglis_r_percent: {
						range: [0, 100]
					},
					ganglis_l_percent: {
						range: [0, 100]
					},
					temporal_r_percent: {
						range: [0, 100]
					},
					temporal_l_percent: {
						range: [0, 100]
					},
					cerebel_r_percent: {
						range: [0, 100]
					},
					cerebel_l_percent: {
						range: [0, 100]
					},
					fileInputPercent: {
						extension: "jpg"
					}

				},
				messages: {
					frontal_r: "Si us plau, indiqueu un valor Frontal Dret entre {0} i {1}.",
					frontal_l: "Si us plau, indiqueu un valor Frontal Esquerre entre {0} i {1}.",
					insula_r: "Si us plau, indiqueu un valor Insula Dret entre {0} i {1}.",
					insula_l: "Si us plau, indiqueu un valor Insula Esquerre entre {0} i {1}.",
					estruclimbiques_r: "Si us plau, indiqueu un valor Estructures límbiques Dret entre {0} i {1}.",
					estruclimbiques_l: "Si us plau, indiqueu un valor Estructures límbiques Esquerre entre {0} i {1}.",
					occipital_r: "Si us plau, indiqueu un valor Occipital Dret entre {0} i {1}.",
					occipital_l: "Si us plau, indiqueu un valor Occipital Esquerre entre {0} i {1}.",
					parietal_r: "Si us plau, indiqueu un valor Parietal Dret entre {0} i {1}.",
					parietal_l: "Si us plau, indiqueu un valor Parietal Esquerre entre {0} i {1}.",
					ganglis_r: "Si us plau, indiqueu un valor Ganglis Dret entre {0} i {1}.",
					ganglis_l: "Si us plau, indiqueu un valor Ganglis Esquerre entre {0} i {1}.",
					temporal_r: "Si us plau, indiqueu un valor Temporal Dret entre {0} i {1}.",
					temporal_l: "Si us plau, indiqueu un valor Temporal Esquerre entre {0} i {1}.",
					cerebel_r: "Si us plau, indiqueu un valor Cerebel Dret entre {0} i {1}.",
					cerebel_l: "Si us plau, indiqueu un valor Cerebel Esquerre entre {0} i {1}.",

					frontal_r_percent: "Si us plau, indiqueu un valor percentual Frontal Dret entre {0} i {1}.",
					frontal_l_percent: "Si us plau, indiqueu un valor percentual Frontal Esquerre entre {0} i {1}.",
					insula_r_percent: "Si us plau, indiqueu un valor percentual Insula Dret entre {0} i {1}.",
					insula_l_percent: "Si us plau, indiqueu un valor percentual Insula Esquerre entre {0} i {1}.",
					estruclimbiques_r_percent: "Si us plau, indiqueu un valor percentual Estructures límbiques Dret entre {0} i {1}.",
					estruclimbiques_l_percent: "Si us plau, indiqueu un valor percentual Estructures límbiques Esquerre entre {0} i {1}.",
					occipital_r_percent: "Si us plau, indiqueu un valor percentual Occipital Dret entre {0} i {1}.",
					occipital_l_percent: "Si us plau, indiqueu un valor percentual Occipital Esquerre entre {0} i {1}.",
					parietal_r_percent: "Si us plau, indiqueu un valor percentual Parietal Dret entre {0} i {1}.",
					parietal_l_percent: "Si us plau, indiqueu un valor percentual Parietal Esquerre entre {0} i {1}.",
					ganglis_r_percent: "Si us plau, indiqueu un valor percentual Ganglis Dret entre {0} i {1}.",
					ganglis_l_percent: "Si us plau, indiqueu un valor percentual Ganglis Esquerre entre {0} i {1}.",
					temporal_r_percent: "Si us plau, indiqueu un valor percentual Temporal Dret entre {0} i {1}.",
					temporal_l_percent: "Si us plau, indiqueu un valor percentual Temporal Esquerre entre {0} i {1}.",
					cerebel_r_percent: "Si us plau, indiqueu un valor percentual Cerebel Dret entre {0} i {1}.",
					cerebel_l_percent: "Si us plau, indiqueu un valor percentual Cerebel Esquerre entre {0} i {1}.",

					fileInput: "Si us plau, pugueu un saveScreen en format .jpg",
					fileInputPercent: "Si us plau, pugueu un saveScreen en format .jpg"
				}


			});

		}
		// Fi Quantitatiu

		/*
		 * Quanticompare

		if ($("body#analyze-form.quanticompare").length) {

			$("#newform").validate({
				 debug:false,
			     errorElement:"span",
			     errorClass:"error",
			     validClass:"",
			     errorLabelContainer: $("#newform div.error"),

			     unhighlight: function(element, errorClass, validClass) {
						if (element.type === 'radio') {
							this.findByName(element.name).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
						} else {
							$(element).parent("div").parent("div").removeClass(errorClass).addClass(validClass);
			                $(element).next("span").addClass("okIcon");
						}
			    },


			    highlight: function(element, errorClass, validClass) {
			    		$("div#error-container").removeClass("hidden");
						if (element.type === 'radio') {
							this.findByName(element.name).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
						} else {

			                $(element).next("span").removeClass("okIcon");
							$(element).parent("div").parent("div").addClass(errorClass).removeClass(validClass);
			                $(element).parent("div").parent("div").css("margin-left","0px")
			                                                      .css("padding-left","0px")
			                                                      .css("padding-top","0px")
			                                                      .css("padding-bottom","0px")
			                                                      .css("margin-bottom","9px");
						}
					},

				rules: {
					frontal_r: {
						range: [0, 100]
					},
					frontal_l: {
						range: [0, 100]
					},
					insula_r: {
						range: [0, 100]
					},
					insula_l: {
						range: [0, 100]
					},
					estruclimbiques_r: {
						range: [0, 100]
					},
					estruclimbiques_l: {
						range: [0, 100]
					},
					occipital_r: {
						range: [0, 100]
					},
					occipital_l: {
						range: [0, 100]
					},
					parietal_r: {
						range: [0, 100]
					},
					parietal_l: {
						range: [0, 100]
					},
					ganglis_r: {
						range: [0, 100]
					},
					ganglis_l: {
						range: [0, 100]
					},
					temporal_r: {
						range: [0, 100]
					},
					temporal_l: {
						range: [0, 100]
					},
					cerebel_r: {
						range: [0, 100]
					},
					cerebel_l: {
						range: [0, 100]
					},
					fileInput: {
						accept:'jpg|jpeg'
					}

				},
				messages: {
					frontal_r: "Si us plau, indiqueu un valor Frontal Dret entre {0} i {1}.",
					frontal_l: "Si us plau, indiqueu un valor Frontal Esquerre entre {0} i {1}.",
					insula_r: "Si us plau, indiqueu un valor Insula Dret entre {0} i {1}.",
					insula_l: "Si us plau, indiqueu un valor Insula Esquerre entre {0} i {1}.",
					estruclimbiques_r: "Si us plau, indiqueu un valor Estructures límbiques Dret entre {0} i {1}.",
					estruclimbiques_l: "Si us plau, indiqueu un valor Estructures límbiques Esquerre entre {0} i {1}.",
					occipital_r: "Si us plau, indiqueu un valor Occipital Dret entre {0} i {1}.",
					occipital_l: "Si us plau, indiqueu un valor Occipital Esquerre entre {0} i {1}.",
					parietal_r: "Si us plau, indiqueu un valor Parietal Dret entre {0} i {1}.",
					parietal_l: "Si us plau, indiqueu un valor Parietal Esquerre entre {0} i {1}.",
					ganglis_r: "Si us plau, indiqueu un valor Ganglis Dret entre {0} i {1}.",
					ganglis_l: "Si us plau, indiqueu un valor Ganglis Esquerre entre {0} i {1}.",
					temporal_r: "Si us plau, indiqueu un valor Temporal Dret entre {0} i {1}.",
					temporal_l: "Si us plau, indiqueu un valor Temporal Esquerre entre {0} i {1}.",
					cerebel_r: "Si us plau, indiqueu un valor Cerebel Dret entre {0} i {1}.",
					cerebel_l: "Si us plau, indiqueu un valor Cerebel Esquerre entre {0} i {1}.",

					fileInput: "Si us plau, pugueu un saveScreen en format .jpg"
				}


			});
		}
		// Fi Quanticompare
		 */
  }

});
